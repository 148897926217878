body {
    background-color: #f9f6f7 !important;
    @include defaultFontStyle(500, 16, 18, $black-color);
    margin: 0;
    overflow-x: hidden;
    height: auto;
}

body p {
    @include defaultFontStyle(400, 14, 18, $black-dark);
    margin: 0 0 20px;
    padding: 0;
    
    @include media480 {
        @include defaultFontStyle(400, 13, 18, $black-dark);
        margin: 0 0 15px;
    }
}

p {
    a {
        color: $green-color;
        text-decoration: underline;
        &:hover {
            color: $black-color;
            text-decoration: underline;
        }
    }
}

.container-fluid {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

.container {
    max-width: 1600px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.w-25 {
    flex: 0 0 25%;
    width: 25%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}

.w-33 {
    width: 33.33%;
    padding: 0 8px;
    @include media991 {
        width: 50%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    @include media575 {
        width: 100%;
    }
}
.w-50 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px;
    @include media991 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.w-75 {
    flex: 0 0 75%;
    max-width: 75%;
    padding: 0 8px;
    @include media991 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
// .w-100 {
//     flex: 0 0 100%;
//     max-width: 100%;
//     padding: 0 8px;
//     @include media991 {
//         flex: 0 0 100%;
//         max-width: 100%;
//         margin-bottom: 15px;
//         &:last-child {
//             margin-bottom: 0px;
//         }
//     }
// }


.white-bg {
    background-color: $white;
}

.gray-bg {
    background-color: $gray-bg;
}

.white_bg {
    background-color: $white;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.w-100 {
    width: 100%;
}

.table {
    width: 100%;
}
table {
    border-collapse: collapse;
}
.gray-bg{
    background: #F9F6F7;
}
@media (max-width: 1199px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.table thead th {
    vertical-align: bottom;
}
.table td,
.table th {
    vertical-align: middle;
}
th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    @include defaultFontStyle(600, 15, 16, $black-color);
    margin: 10px 0 8px;
    display: block;
    text-align: left;
    @include media767 {
        @include defaultFontStyle(600, 14, 16, $black-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    @include media575 {
        text-overflow: unset;
        white-space: unset;
        overflow: unset;
        font-size: 12px;
    }
    span {
        @include defaultFontStyle(600, 13, 16, $black-dark);
        @include media991 {
            @include defaultFontStyle(600, 12, 16, $black-dark);
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;
}
.d-flex {
    display: flex;
}
.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-center{
    justify-content: center;
}
.flex-align {
    display: flex;
    align-content: center;
    justify-content: center;
}
.p-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.validMsg {
    margin-top: 5px;
    display: block;
    @include defaultFontStyle(600, 13, 1.2, $text-red);
}

.display-flex{
    display: flex;
    align-items: center;
}
.justify-center{
    justify-content: center;
}


.bc_relative {
    position: relative;
    width: 100%;
    margin-right: 20px;
    &:last-child{
        margin-right: 0;
    }
    .bc_datepicker-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 16px;
        bottom: 8%;
        transform: translateY(-50%);
        @include media480 {
            width: 15px;
            height: 15px;
        }
        &.active {
            bottom: 35%;
        }
    }
    input {
        box-shadow: none;
        height: 45px;
        width: 100%;
        padding: 10px;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
        @include defaultFontStyle(500, 16, 1.2, $black-color);
        @include media480 {
            height: 40px;
            @include defaultFontStyle(400, 14, 1.2, $black-color);
        }
        &:disabled {
            background: $gray-btn;
            border: 1px solid transparent;
        }
    }
    .react-datepicker {
        &-wrapper {
            width: 100% !important;
        }
        &__triangle {
            left: 50% !important;
            transform: translateY(-50%);
            margin-top: -3px !important;
        }
    }
}

// table
.bg-green{
    background-color: $green-btn-light;
}
.bg-black{
    background-color: $black-dark;
}

.table {
    th,th { 
        padding: 21px 30px;
        border: 1px solid #E5E5E5;
    }  
    th{
        @include defaultFontStyle(500, 16, 1.2, $white);
        border: 1px solid #E5E5E5;
        &:first-child {
            border-radius: 8px 0 0 0;
        }
        &:last-child {
            border-radius: 0 8px 0 0;
        }
    }
    td {
        @include defaultFontStyle(400, 14, 1.2, $black-light);
        border: 1px solid #E5E5E5;
    }
}

.page-title{
    @include defaultFontStyle(700,26,28,$black-dark);
    padding: 40px 0;
    margin: 0;
    @include media991{
        padding: 20px 0;
    }
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 50px;
    padding: 10px 0;
    &:last-child {
        margin-right: 0;
    }
    input {
        position: absolute;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        cursor: pointer;
    }
    .square {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        transition: all 0.5s ease 0s;
        background-color: $white;
        border: 1px solid rgba(53, 53, 65, 0.5);
        margin-right: 12px;
    }
    .square:after {
        content: "";
        display: block;
        position: absolute;
        top: 4px;
        left: 7px;
        width: 5px;
        height: 8px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .circle {
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.5s ease 0s;
        background-color: $white;
        border: 1px solid rgba(53, 53, 65, 0.5);
        margin-right: 12px;
    }
    .circle:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: $green-btn-light;
        opacity: 0;
    }
    label {
        @include defaultFontStyle(400, 14, 16, $black-light);
    }
    input:checked ~ .square::after,
    input:checked ~ .circle::after {
        opacity: 1;
    }
    input:checked ~ .circle {
        border: 1px solid $green-btn-light;
    }
    input:checked ~ .square {
        background-color: $green-btn-light;
        border: 1px solid $green-btn-light;
    }
}
.select_report_sub{
    display: flex;
    flex-wrap: wrap;
    .checkbox{
        margin-right: 25px;
        padding-top: 0;
    }
}
.analyzr-iframe {
    height: 100%;
    overflow: auto;
}

// loader
.bc-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999999;
    display: flex;
    img,
    svg {
      margin: auto;
    }
}

// .myreport-table{
//     .table-responsive-xl > .ant-table-wrapper {
//         min-height: 300px;
//     }
// }
