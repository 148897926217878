.btn, .btn:focus, .btn:active, .btn:visited {
    box-shadow: none;
    background-color: $green-color;
    color: $white;
    border: 1px solid $green-color;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px; 
    line-height: 20px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    -webkit-transition: all 0.5s ease 0s;
     -moz-transition: all 0.5s ease 0s;
     -ms-transition: all 0.5s ease 0s;
     -o-transition: all 0.5s ease 0s;
     transition: all 0.5s ease 0s;
     @include media1199 {  
        padding: 12px 26px;  
        font-size: 15px;  
    }
    @include media767 { 
        padding: 12px 20px;
        font-size: 14px;
    }
    @include media350 { 
        padding: 12px 10px;
        font-size: 13px;
    }
    &.trans, &.trans:focus, &.trans:active, &.trans:visited {
        border: 1px solid $green-color;
        color: $green-color;
        background-color: transparent;
        border-radius: 5px;
        &:hover {
           background-color: $green-color;
           color: $white;
           border-radius: 5px;
           border: 1px solid $green-color;
           box-shadow: 0px 0px 17px -5px $green-color;
       }
    }
    &.trans-black, &.trans-black:focus, &.trans-black:active, &.trans-black:visited {
        border: 1px solid $black-light;
        color: $black-light;
        background-color: transparent;
        border-radius: 5px;
        &:hover {
           background-color: $black-light;
           color: $white;
           border-radius: 5px;
           border: 1px solid $black-light;
           box-shadow: 0px 0px 17px -5px $black-light;
       }
    }
    &.light-green, &.light-green:focus, &.light-green:active, &.light-green:visited {
        background-color: $green-btn-light;
        color: $white;
        border: 1px solid $green-btn-light;
        border-radius: 5px;
        &:hover {
            border: 1px solid $green-btn-light;
            border-radius: 5px;
            color: $green-btn-light;
            background-color: $white;
            box-shadow: 0px 0px 17px -5px $green-color;
       }
    }
    &:hover {
        border: 1px solid $green-color;
        border-radius: 5px;
        color: $green-color;
        background-color: $white;
        box-shadow: 0px 0px 17px -5px $green-color;
    }
 }
 .blue_btn, .blue_btn:focus, .blue_btn:active, .blue_btn:visited {
    box-shadow: none;
    background-color: $blue;
    color: $white;
    border: 2px solid transparent;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 36px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px; 
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.5s ease 0s;
     -moz-transition: all 0.5s ease 0s;
     -ms-transition: all 0.5s ease 0s;
     -o-transition: all 0.5s ease 0s;
     transition: all 0.5s ease 0s;
     @include media1199 {  
        padding: 13px 26px;  
        font-size: 15px;  
    }
    @include media767 { 
        padding: 12px 20px;
        font-size: 14px;
    }
    &.trans, &.trans:focus, &.trans:active, &.trans:visited {
        border: 2px solid transparent;
        color: $blue;
        background-color: $gray-btn;
        border-radius: 5px;
        &:hover {
            background-color: $blue;
            color: $white;
            border-radius: 5px;
            border: 2px solid $blue;
            box-shadow: 0px 0px 17px -5px rgba(77,138,174,0.9);
        }
    }
    &:hover {
        border: 2px solid $blue;
        border-radius: 5px;
        color: $blue;
        background-color: $white;
        box-shadow: 0px 0px 17px -5px rgba(77,138,174,0.9);
    }
 }
 .btn-trans{
     @include defaultFontStyle(500, 16, 1.2, $black-light);
    background-color: transparent;
    border: 1px solid $black-light;
    border-radius: 8px;
    cursor: pointer;
    -webkit-transition: all 0.5s ease 0s;
     -moz-transition: all 0.5s ease 0s;
     -ms-transition: all 0.5s ease 0s;
     -o-transition: all 0.5s ease 0s;
     transition: all 0.5s ease 0s;
     &:hover{
         background-color: $black-light;
         color: $white;
         box-shadow: 0px 0px 17px -5px $black-dark;
     }
 }
 .btn-filter{
     @include defaultFontStyle(500, 16, 1.2, $black-light);
     padding: 17px;
     i{
         margin-right: 12px;
     }
 }
 
// myamalyer 
.grey-btn, .grey-btn:focus, .grey-btn:active, .grey-btn:hover{
    background: #F8F6F7;
    color: $black-dark;
    border: 1px solid rgba(53, 53, 65, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    padding: 13px 35px;
    border-radius: 5px;
    text-align: center;
    font-size: 16px; 
    line-height: 20px;
    font-weight: 500;
    outline: none;
    align-items: center;
    width: max-content;
    cursor: pointer;
}
