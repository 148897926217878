.common-modal {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity 0.15s linear;
    padding: 0;
    z-index: 1072;
    &.show {
        display: block;
        opacity: 1;
    }
    .modal-dialog {
        position: relative;
        width: auto;
        pointer-events: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        max-width: 700px;
        margin: 1.75rem auto;
        min-height: calc(100% - 3.5rem);
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px);
        @include media767 {
            margin: 1.75rem 15px;
            max-width: 100%;
        }
    }
    &.show {
        .modal-dialog {
            -webkit-transform: none;
            transform: none;
        }
    }
    .modal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: $white;
        background-clip: padding-box;
        border-radius: 4px;
        outline: 0;
        box-shadow: 0px 10px 5px -7px rgba(0, 0, 0, 0.09);
        .modal-header {
            display: flex;
            align-content: center;
            justify-content: space-between;
            padding: 25px;
            border-radius: 4px 4px 0 0;
            @include media575 {
                padding: 15px 25px;
            }
            @include media480 {
                padding: 15px;
            }
            h3 {
                @include defaultFontStyle(600, 22, 1.4, $black-color);
                @include media575 {
                    @include defaultFontStyle(600, 18, 1.4, $black-color);
                }
            }
            .modal_close {
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include media575 {
                    height: 25px;
                    width: 25px;
                }
                img {
                    width: 18px;
                    height: 18px;
                    @include media991 {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            &.green-header {
                background-color: $green-btn-light;
                h3 {
                    color: $white;
                }
            }
        }
        .modal-body {
            .next-button {
                padding: 15px 0;
                .btn {
                    margin: 0 5px;
                }
            }
            .file_custom_upload {
                padding: 40px 10px;
            }
        }
        .onlymodal_close {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            right: 15px;
            position: absolute;
            top: 15px;
            left: auto;
            z-index: 3;
            @include media575 {
                height: 25px;
                width: 25px;
            }
            img {
                width: 18px;
                height: 18px;
                @include media991 {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}

body.modal-open {
    padding-right: 17px;
    height: 100%;
    overflow: hidden;
    width: 100%;
    // position: fixed;
    .common-modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1071;
        width: 100vw;
        height: 100vh;
        opacity: 0.5;
        background-color: $black-color;
    }
}


.alert-msg{
    padding: 50px;
    p{
        margin-bottom: 0;
        text-align: center;
        @include defaultFontStyle(700,22,18,$black-color)
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #BABECA; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#6C7B95; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #B2B9C6; 
  }
