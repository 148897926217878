.form-group {
    margin-bottom: 15px;
    label {
        margin-bottom: 8px;
        @include defaultFontStyle(500, 15, 16, $black-color);
        display: block;
        @include media1199 { 
            @include defaultFontStyle(500, 14, 16, $black-color);
        }
    }
    .svg_icon {
        position: relative;
        img {
            position: absolute;
            right: 16px;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 11px;     
            @include media480 { 
                width: 20px;
                height: 20px;
                top: 10px;
            }
        }
        .form-control, .form-control:active, .form-control:focus {
            padding: 10px 35px 10px 10px;
        }
    }
    p  {
        margin-bottom: 0;
        color: $black-color;
        margin-top: 8px;
        > a {
            color: $blue;
            // margin-left: 5px;
            &:hover {
                color: $green-color;
            }
        }
        @include media1199 { 
            font-size: 13px;
        }
        @include media350 { 
            font-size: 12px;
        }
    }
    .or-with {
        padding: 10px 0;
        position: relative;
        display: block;
        text-align: center;
        &:after, &:before {
            position: absolute;
            height: 2px;
            top: 18px;
            width: 35%;
            background-color: $gray-btn;
            content: "";        
        }
        &:after {
            left: 30px;
            right: auto;
        }
        &:before {
            right: 30px;
            left: auto;
        }
    }
    .form-control, .form-control:active, .form-control:focus {
        box-shadow: none;
        height: 45px;
        width: 100%;
        padding: 10px;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        @include defaultFontStyle(500, 16, 16, $black-color);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @include media480 {
            height: 40px;
            @include defaultFontStyle(400, 14, 16, $black-color);
        }
        &:hover, &:visited, &:active, &:focus {
            border: 1px solid $black-dark;
            color: $black-color;
        }
        &:disabled {
            background: $gray-btn;
            border: 1px solid transparent;
        } 
    }
    .input-group {
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        border: 1px solid $gray-btn;
        border-radius: 4px;
        .counrty-no {
            position: relative;
            width: auto;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            .custom-select, .custom-select:active, .custom-select:focus {
                box-shadow: none;
                height: 45px;
                font-size: 15px;
                color: $black-dark;
                font-weight: 500;
                border: none;
                width: auto;
                padding: 10px 0px 10px 10px;
                outline: none;
                border-radius: 4px;
                appearance: auto;
                background: transparent;
                @include media480 { 
                    height: 40px;
                    padding: 10px 0px 10px 5px;
                    font-size: 14px;
                }
                option {
                    color: $black-color;
                    padding: 5px;
                    font-size: 15px;
                }
            }
            &:after {
                position: absolute;
                content: "";
                left: auto;
                right: -4px;
                height: 35px;
                top: 5px;
                bottom: 5px;
                background: $gray-btn;
                width: 1px;
            }
        }
        .form-control {
            border: none !important;
            border-radius: 4px;
        }
        &:hover, &:visited, &:active, &:focus {
            border: 1px solid $black-dark;
            color: $black-color;
            border-radius: 4px;
        }
    }
}
textarea {
    box-shadow: none;
    width: 100%;
    padding: 10px;
    border: 1px solid $gray-btn;
    border-radius: 4px;
    @include defaultFontStyle(500, 16, 16, $black-color);
    resize: vertical;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover, &:visited, &:active, &:focus {
        border: 1px solid $black-dark;
        color: $black-color;
    }
}

.custom-select {
    background: url("../../assets/images/social/down_arrow.svg") no-repeat 96% center #fff;
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px 25px 10px 10px;
    border: 1px solid $gray-btn;
    border-radius: 4px;
    @include defaultFontStyle(500, 15, 16, $black-dark);
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover, &:visited, &:active, &:focus {
        border: 1px solid $black-dark;
        color: $black-color;
        outline: none;
    }
    option {
        @include defaultFontStyle(500, 16, 1.4, $black-color); 
    }
    @include media480 {
        height: 40px;
        @include defaultFontStyle(400, 14, 16, $black-dark);
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background:  url("../../../src/assets/images/myreport/calendar.svg") no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    cursor: pointer;
    &:focus{
        outline: none;
    }
}
input[type="number"]:hover{
    -webkit-appearance: none;
}