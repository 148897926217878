$primary-font: 'Helvetica Now Text';
$secondary-font: 'Roboto';


$white: #FFFFFF;
$black-color: #28263D;
$gray-light: #E1E2E7;
$gray-bg: #E5E5E5; 
$blue: #4D8AAE;
$text-red: #FE3434;
$bg-red-light: #FFE0E0;
$menu-bg: #EDF3F7;
$green-color: #7CBD42;
$light-white: #F7F8FB;
$border2: #dfd4d8;

// question input 
$text_color: #31313D;
$gra-dark: #555658;
$gra-input: #75787B;
$gra-bg: #CCD3D9;
$lightgray: #eeeced;

/* Button color */
$green-btn: #7CBD42;
$gray-btn: #E5E9F2;
// $green-btn-light: #96CF04;
$green-btn-light: #7DBD42;
  
// report 
$black-dark:#353541;
$black:#000000;
$black-light:#1B1B1B;
$grey-light:#F8F6F7;

// timeline-color
$gray-count: #EEECED;
$gray-text: #BABECA;

// responsive view variables
// Small smartphones
$screen-350: 350px;
// Small smartphones
$screen-480: 480px;
// Small tablets and large smartphones (landscape view)
$screen-575: 575px;

$screen-675: 675px;
// Small tablets (portrait view)
$screen-767: 767px;
// Tablets and small desktops
$screen-991: 991px;
// Large tablets and desktops
$screen-1199: 1199px;
// extra Large tablets and desktops
$screen-1366: 1366px;
//extra Large tablets and desktops
$screen-1429: 1429px;
// extra Large desktops
$screen-1599: 1599px;
