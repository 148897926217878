@import "style/helpers/variable.scss";
@import "style/helpers/mixins.scss";

@import "style/base/reset.scss";
@import "style/base/common.scss";


@import "style/font-awsome/fontawesome.scss";
@import "style/font-awsome/solid.scss";
@import "style/font-awsome/brands.scss";


@import "style/components/button.scss";
@import "style/components/input.scss";
@import "style/components/modal.scss";
@import "style/components/typography.scss";


@import "style/modules/myreport.scss";
@import "style/base/antdCustome.scss";

// @import "style/modules/footer.scss";
