.analyzr_header{
    padding: 14.5px 10px;
    background: $white;
    img{
        // width: 160px;
        height: 32px;
    }
}
.btn-back-to-top {
    position: fixed;
    bottom: 60px;
    right: 20px;
    display: block;
    background-color: $green-btn-light;
    border: 1px solid #7DBD42;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 #00000033, 0 2px 10px 0 #0000001a;
    padding: 11px 16px;
    width: 50px;
    height: 50px;
    z-index: 999;
    cursor: pointer;
    img{
        width: 19px;
        filter: brightness(0) invert(1);
    }
    &:hover{
        background-color: $green-btn-light !important;
        opacity: 0.8;
    }
}
.back_to_home{
    width: 300px;
    margin-right: 15px;
    @include media767 {
        margin-bottom: 15px;
    }
    a{
        display: flex;
        align-items: center;
        @include defaultFontStyle(500, 17, 16, $text_color);
        margin-bottom: 0;
        &:hover{
            color:$text_color;
        }
        img{
            margin-right: 10px;
            margin-left: 0;
        }
    }
}
.page_of_no{
    @include defaultFontStyle(500, 15, 16, #000000a3);
    padding: 30px 0 20px;
    
}
.main-tab-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0;
    @include media991 {
        padding: 15px 0;
    }
    .tabs {
        display: flex;
        align-items: center;
        background: $white;
        // max-width: 353px;
        width: 19%;
        border-radius: 10px;
        padding: 5px 6px;
        border: 1.2px solid $white;
        margin: 15px;
        cursor: pointer;
        @include media991 {
            width: 100%;
            max-width: 164px;
            margin: 10px;
        }
        @include media767 {
            // width: 50%;
            max-width: 243px;
            width: 100%;
            margin: 8px;
        }
        @include media575 {
            margin: 5px;
        }
        .tab-icon {
            background: #96cf041a;
            border-radius: 10px;
            width: 53px;
            height: 53px;
            display: flex;
            align-items: center;
            margin-right: 18px;
            transition: all 0.5s ease 0s;
            @include media1429 {
                width: 43px;
                height: 43px;
                margin-right: 10px;
                img {
                    width: 20px;
                }
            }
        }
        &:hover,
        &.active {
            border: 1.2px solid $green-btn-light;
            box-shadow: 10px 7px 15px #00000012;
            border-radius: 10px;
            transition: all 0.5s ease 0s;
            .tab-icon {
                background: $green-btn-light;
                transition: all 0.5s ease 0s;

                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
        .tab-name {
            @include defaultFontStyle(500, 20, 23, $black-dark);
            @include media1429 {
                font-size: 18px;
            }
            @include media1199 {
                font-size: 16px;
            }
        }
    }
}

.tab-info-section {
    padding-bottom:60px;
    // @include media767 {
    //     padding: 41px 20px;
    // }
}

.title-filter-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 24px;
    @include media575 {
        display: block;
    }
    .filter-name {
        h3 {
            @include defaultFontStyle(bold, 26, 28, $black-dark);
            margin-bottom: 0;
            @include media575 {
                margin-bottom: 15px;
            }
        }
        p{
            @include defaultFontStyle(500,16, 28, $black-dark);
            margin: 0;
            padding-top: 5px;
        }
    }
    .filter-btn {
        display: flex;
        @include media575 {
            display: block;
        }   
        
        .view-report-btn,
        .view-report-btn:focus,
        .view-report-btn:active,
        .view-report-btn:hover {
            background: #E2E2E2;
            border-radius: 8px;
            color: $text_color;
            // border: 1px solid rgba(53, 53, 65, 0.2);
            box-sizing: border-box;
            border-radius: 5px;
            display: inline-block;
            vertical-align: middle;
            padding: 13px 21px;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            outline: none;
            display: flex;
            align-items: center;
            width: max-content;
            margin-right: 15px;
            cursor: pointer;
            border: none;
            justify-content: center;

            &.view-report-btn-rev {
                background: $black-light;
                color: $white;
                border: 2px solid transparent;
                padding: 3px 10px;
                img{
                    min-width: 125px;
                    max-height: 41px;
                    min-height: 41px;
                    max-width: 120px;
                    border-left: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }
            }

            img {
                padding-left: 7px;
                margin-left: 7px;
                margin-right: 0;
                border-left: 1px solid #31313d29;
            }

            @include media575 {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }

            &:hover {
                background-color: $black-light;
                color: $white;
                box-shadow: 0 0 17px -5px #353541;

                &.view-report-btn-rev {
                    background: $white;
                    color: $text_color;
                    border: 2px solid $black-light;
                    box-shadow: 0 0 17px -5px $black-dark;
                    img{
                        border-left: none;
                        filter: brightness(100) invert(1);
                    }
                }

                img {
                    filter: brightness(0) invert(1);
                }
            }

        }
        .btn {
            background-color: $green-btn-light;
            border: 2px solid $green-btn-light;
            padding: 13px 21px;
            @include media991 {
                padding: 12px;
            }
            @include media575 {
                width: 100%;
            } 
            &:hover {
                background-color: $white;
            }
        }
    }
    &.purchase-report{
        justify-content: flex-start;
        .view-report-btn{
            margin-left: 15px;
        }
    }
}

.applied-title-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 24px;
    // margin-bottom: 24px;
    @include media767 {
        display: block;
    }
    
    .applied-title {
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        h6 {
            @include defaultFontStyle(500, 17, 16, $black-light);
            margin-bottom: 0;
            margin-right: 15px;
            padding: 10px 0;
        }
        .filter-tag {
            display: flex;
            flex-wrap: wrap;
            div {
                display: flex;
                flex-wrap: wrap;
                background-color: $text_color;
                // border: 1px solid $black-light;
                @include defaultFontStyle(500, 12, 16, $white);
                padding: 4px 8px;
                border-radius: 8px;
                margin-right: 16px;
                margin: 4px 6px 4px 0;
                img {
                    margin: 0;
                    margin-left: 10px;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
    .applied-btn {
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-filter {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            background: transparent;
            border: none;
            cursor: pointer;
            padding: 17px 10px 17px 17px;
            img{
                width: 20px;
                margin-left: 10px;
            }
            @include media991 {
                padding: 12px;
            }
        }
    }
}
.filter-form {
    form {
        background-color: $white;
        padding: 33px 18px;
        border-radius: 10px;
        margin-bottom: 40px;
        [class*="col-"] {
            margin-bottom: 15px;
        }
    }
}
.myreport-table {
    .ant-table-thead {
        th {
            background-color: #E2E2E2;
            color: $text_color;
            white-space: nowrap;
            &.ant-table-column-has-sorters {
                // background-color: $black-light;
                &:hover {
                    background-color: #e2e2e29c;
                }
            }
        }
    }
    .error_status{
        background: #F84538;
        border-radius: 8px;
        color: $white;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
    .pending_status{
        border: 1px solid rgba(49, 49, 61, 0.16);
        border-radius: 8px;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
    .complete_status{
        // background: #7DBD42;
        // color: $white;
        border-radius: 8px;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
    }
    .imgDevice{
        display: flex;
        align-items: center;
        img{
            width: 20px;
            margin-left: 5px;
        }
    }
    .ant-table-tbody {
        > tr{
            &.ant-table-row{
                &:hover{
                    > td{
                        background: #e2e2e27a;
                    }
                }
            }
            > td{
                border-top: 12px solid #f9f6f7;
            }
        }
        // .ant-table-row-expand-icon-cell{
        //     .anticon{
        //         svg{
        //             transform: rotate(-90deg);
        //         }
        //     } 
        // }
        // tr:nth-child(even) {
        //     background: #f2f5f7;
        // }
        // tr:nth-child(odd) {
        //     background: #fff;
        // }
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 8px;
    }
    .ant-table-container table > thead > tr:first-child th:last-child {
        border-top-right-radius: 8px;
    }
    .table-icon {
        // bac  kground-color: #e5e9ec;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        margin-right: 8px;
        &:last-child {
            margin-right: 0;
        }
        img {
            margin: 0;
            width: 18px;
        }
    }
    .ant-empty-image {
        display: none;
    }
    .no-data {
        max-width: 686px;
        margin: auto;
        padding: 70px 0;
        img {
            margin-bottom: 6px;
        }
        h5 {
            @include defaultFontStyle(700, 18, 24, $black-light);
            margin-bottom: 10px;
        }
        p {
            @include defaultFontStyle(500, 14, 22, #353541);
        }
    }
}
// AnalyzrTab table
.analyzrTab-table,
.checkout-table {
    .table {
        @include media767 {
            white-space: nowrap;
        }
        thead {
            th {
                @include defaultFontStyle(500, 16, 16, $white);
                padding: 21px 30px;
                border: 1px solid #e5e5e5;

                &:first-child {
                    border-radius: 8px 0 0 0;
                }
                &:last-child {
                    border-radius: 0 8px 0 0;
                }
            }
        }
        tbody {
            background-color: #fff;
            tr {
                &:nth-child(odd) {
                    background-color: #f2f5f7;
                }
            }
            td {
                @include defaultFontStyle(400, 14, 14, $black-light);
                padding: 14px 30px;
                border: 1px solid #e5e5e5;
            }
        }
    }
    &.header_sticky{
        .table-responsive-xl{
            max-height: 500px;
            overflow: auto;
        }
        .table{
            thead{
                position: -webkit-sticky;
                position: sticky;
                top: -1px;
                z-index: 1;
            }
           
        }
    }
    &.grey-table{
        .table{
            thead{
                th{
                    background-color: #E2E2E2;
                    color: #31313D;
                    border: 0;
                    font-weight: 400;
                }
            }
            tbody{
                tr{
                    &:nth-child(even){
                        background-color: $white;
                    }
                    &:hover{
                        background: #e2e2e27a;
                    }
                }
                td{
                    border: 0;
                    border-top: 15px solid #f9f6f7;
                }
            }
        }
       
    }

}

// create report
.create-report {
    max-width: 1120px;
    margin: 0 auto;
    .credits-remaining {
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        @include media767 {
            flex-wrap: wrap;
        }
        .report-card {
            max-width: 540px;
            width: 100%;
            height: 214px;
            background: #f8f6f7;
            border: 1px solid rgba(53, 53, 65, 0.2);
            box-sizing: border-box;
            border-radius: 10px;
            margin-right: 40px;
            &:hover {
                background-color: $green-btn-light;
                .report-info {
                    .card-icon {
                        background: #f8f6f7;
                        img {
                            filter: none;
                        }
                    }
                    p {
                        color: $white;
                    }
                }
            }
            &.bg-green-card {
                // background: $green-btn-light;
                // border: 0;
                position: relative;
                margin-right: 0;
                .bg-file-icon {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                // .report-info {
                //     .card-icon {
                //         background: $white;
                //     }
                //     p {
                //         @include defaultFontStyle(500, 18, 16, $white);
                //     }
                // }
            }
            @include media767 {
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 30px;
            }
            .report-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 212px;
                .card-icon {
                    background: $green-btn-light;
                    border: 1.3px solid $green-btn-light;
                    box-sizing: border-box;
                    border-radius: 7.54717px;
                    width: 45px;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    img {
                        filter: brightness(0) invert(1);
                    }
                }
                p {
                    @include defaultFontStyle(500, 18, 16, $black-light);
                    padding-top: 12px;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}
.templates-btn {
    flex-wrap: wrap;

    .grey-btn {
        padding: 15px 35px;
        @include media480 {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    .btn {
        background-color: $green-btn-light;
        border: 2px solid $green-btn-light;
        padding: 14px 35px;
        @include media480 {
            width: 100%;
        }
        &:hover {
            border: 2px solid $green-btn-light;
            background: $white;
        }
    }
}
// generateTemplate
.ReportTemplate {
    .ReportTemplate-info {
        .Template-info {
            background: $grey-light;
            border: 1px solid rgba(53, 53, 65, 0.2);
            box-sizing: border-box;
            border-radius: 10px;
            padding: 60px 10px;

            .Template-icon {
                background: $green-btn-light;
                border: 1.3px solid $green-btn-light;
                box-sizing: border-box;
                border-radius: 7.54717px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                margin: auto;
                img {
                    filter: brightness(0) invert(1);
                }
            }
            h2 {
                @include defaultFontStyle(500, 18, 16, $black-light);
                padding-top: 6px;
                text-align: center;
            }
            p {
                @include defaultFontStyle(400, 14, 16, $black-dark);
                padding-top: 10px;
                text-align: center;
                max-width: 693px;
                margin: auto;
            }
        }
    }
    &.custom-reportTemplate {
        .Template-info {
            .Template-icon {
                img {
                    filter: brightness(0) invert(1);
                }
            }
            p {
                max-width: 1136px;
            }
        }
    }
    .templates-btn {
        margin-top: 40px;
    }
}
// contactus us
.contactus-tab {
    margin-top: 35px;
    .contact-form {
        background: #f8f6f7;
        border: 1px solid rgba(53, 53, 65, 0.2);
        box-sizing: border-box;
        border-radius: 10px;
        padding: 30px;
        .form-group {
            &::after {
                content: "";
                display: table;
                clear: both;
            }
            .inout-label {
                float: left;
                width: 10%;
                @include media991 {
                    width: 20%;
                }
                @include media767 {
                    width: 100%;
                }
                // label {
                //     padding: 11px 12px 11px 0;
                //     display: inline-block;
                //     @include defaultFontStyle(500, 17, 16, $black-light);
                //     margin-bottom: 0;
                // }
            }
            .input-control {
                float: left;
                width: 90%;
                @include media991 {
                    width: 80%;
                }
                @include media767 {
                    width: 100%;
                }

                .subject-selectbox {
                    max-width: 290px;
                    width: 100%;
                    @include media767 {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

// Report Menu
.report-menu-contact{
    border: 1px solid #31313d29;
    border-radius: 8px;
    padding: 32px 10px;
    margin-top: 30px;
    .contact-info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 24px;
        }
        p{
            @include defaultFontStyle(500, 28, 48, $text_color);
            // padding-top: 30px;
            margin-bottom: 30px;
        }
    }
}
.report-menu {
    .report-menu-title {
        @include defaultFontStyle(500, 22, 28, $black-dark);
        padding: 30px 0 25px;
        // border-top: 1px solid #d7d7d9;
    }
    .report-menu-section {
        .row {
            margin-right: -15px;
            margin-left: -15px;
        }
        .disable{
            opacity: 0.8;
        }
        .w-25 {
            padding: 0 15px;
            width: 25%;
            display: flex;
            @include media1366{
                flex: 0 0 33.33%;
                width: 33.33%;
            }
            @include media991 {
                flex: 0 0 50%;
                width: 50%;
            }
            @include media575 {
                flex: 0 0 100%;
                width: 100%;
            }
        }
        .report-menu-box {
            // border: 1.5px solid #e5e5e5;
            background-color: $white;
            // background-color: $grey-light;
            border-radius: 10px;
            margin-bottom: 20px;
            border-radius: 8px;
            padding: 16px;
            width: 100%;
            img {
                height: 270px;
                width: 100%;
            }
           
            .report-menu-info {
                padding: 40px 0 0;
                position: relative;
                // background-color: $white;
                // border-radius: 8px;
                h5 {
                    @include defaultFontStyle(500, 18, 28, $black-dark);
                    cursor: pointer;
                    margin-bottom: 0;
                    word-break: break-word;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                button {
                    padding: 5px 12px;
                    font-size: 15px;
                    background-color: $white;
                    border: 2px solid #000;
                }
                .filter-btn{
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a{
                        display: flex;
                        align-items: center;
                    }
                    .grey-btn{
                        background: #E2E2E2;
                        border: none;
                        @include defaultFontStyle(500, 12, 16, $text_color);
                        padding: 4px 8px;
                        border-radius: 8px;
                        margin-right: 10px;
                        display: flex;
                        &.unselect{
                            background: $green-btn-light;
                            color: $white;
                        }
                    }
                    .btn{
                        @include defaultFontStyle(500, 12, 16, $white);
                        padding: 4px 8px;
                        &:hover {
                            border: 1px solid $green-color;
                            border-radius: 5px;
                            color: $white;
                            background-color: $green-color;
                            box-shadow: unset;
                        }
                    }
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                    }
                }
                .tooltip-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button {
                        margin-left: 20px;
                        border-radius: 50%;
                        padding: 5px;
                        font-size: 15px;
                        font-weight: bolder;
                        min-width: 30px;
                        height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
                .report-tag {
                    display: flex;
                    flex-wrap: wrap;
                    padding-top: 10px;
                    h4 {
                        @include defaultFontStyle(400, 14, 16, $black-light);
                        background-color: $white;
                        padding: 8px 20px;
                        border-radius: 16px;
                        margin: 5px 16px 0 0;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .device_bg{
                    background: $text_color;
                    border-radius: 8px;
                    padding: 10px;
                    width: max-content;
                    margin: auto;
                    position: absolute;
                    top: -30px;
                    left: 0;
                    right: 0;
                    .device-img {
                        display: flex;
                        img {
                            height: 25px;
                            width: 25px;
                            border-radius: 100%;
                            margin: initial;
                            margin-right: 8px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                
            }
            &.select {
                border: 1.5px solid $green-color;
                h5 {
                    color: $green-color;
                }
            }
            .report-hover {
                position: relative;
                .report-image {
                    display: block;
                    width: 100%;
                    height: 270px;
                    border-radius: 10px 10px 0 0;
                }
                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    transition: 0.5s ease;
                    background-color: #353541;
                    cursor: pointer;
                    border-radius: 10px 10px 0 0;
                    .search-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        background: #ffffff85;
                        border-radius: 100%;
                        padding: 15px;
                        img {
                            filter: brightness(0) invert(1);
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
                &:hover {
                    .overlay {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

// Request Custom Report
hr {
    margin: 40px 0 30px;
    border-radius: 10px;
    border: 1px solid #35354114;
}
font {
    color: $text-red;
}
.report-data {
    h5 {
        @include defaultFontStyle(500, 22, 28, $black-dark);
        margin-bottom: 20px;
    }
    p {
        @include defaultFontStyle(400, 14, 22, $black-light);
        padding: 10px 0;
        margin: 0;
    }
    .form-group {
        .input-group {
            border: 1px solid $gray-bg;
            &.player-below {
                max-width: 472px;
            }
            &.que-7 {
                max-width: 1059px;
            }
            .form-control {
                background-color: #f2f5f7;
                @include defaultFontStyle(400, 14, 16, $black-light);
            }
        }
    }
    .upload-doc {
        background: #f2f5f7;
        border: 1px dashed #e5e5e5;
        border-radius: 5px;
        padding: 50px 10px;
        cursor: pointer;
        input {
            display: none;
        }
        label {
            @include defaultFontStyle(500, 18, 24, $black-light);
            text-align: center;
            cursor: pointer;
        }
    }
}
.que-review {
    padding: 18px 25px;
    border-radius: 5px;
    p {
        @include defaultFontStyle(500, 20, 23, $white);
        margin: 0;
    }
}
.select-data {
    .table {
        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $white;
                }
                &:nth-child(even) {
                    background-color: #f2f5f7;
                }
            }
            td {
                padding: 2px 30px;

                &.no-data {
                    padding: 35px;
                    font-size: 16px;
                }
            }
        }
        @include media1199 {
            .bc_relative {
                width: 150px !important;
            }
        }
    }
}
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

// Generate Template

.report-subject {
    .table {
        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: #f2f5f7;
                }
                &:nth-child(even) {
                    background-color: #f2f5f7;
                }
            }
            td {
                padding: 2px 30px;
            }
        }
    }
}
.generateReport-menu {
    .report-menu-section {
        .report-menu-box {
            margin-bottom: 30px;
            border: 1px solid transparent;

            &:hover{
                border: 1px solid $green-btn-light;
            }
        }
    }
}

/* popup css */

.modal {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    &.is-visible {
        visibility: visible;
        .modal-overlay {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
        .modal-wrapper {
            &.modal-transition {
                opacity: 1;
            }
        }
    }
    .modal-overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: hsla(0, 0%, 0%, 0.5);
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.3s, opacity 0.3s;
        pointer-events: none;
    }
    .modal-wrapper {
        position: absolute;
        z-index: 9999;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) !important;
        max-width: 773px;
        width: 90%;
        background-color: #fff;
        box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        .modal-content {
            .details {
                img {
                    width: 773px;
                    height: 525px;
                    border-radius: 15px;
                }
            }
        }
        &.modal-transition {
            opacity: 0;
        }
        .modal-close {
            position: absolute;
            top: -30px;
            right: -25px;
            padding: 1em;
            color: #fff;
            border: 0;
            background: none;
            cursor: pointer;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.pagination-align {
    display: flex;
    justify-content: flex-end;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px;
    border-radius: 4px;
    > li {
        display: inline;
        > a,
        > span {
            position: relative;
            float: left;
            padding: 6px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: $black-light;
            text-decoration: none;
            background-color: $white;
            border: 1px solid $border2;
        }
        &:first-child {
            > a,
            > span {
                margin-left: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        &:last-child {
            > a,
            > span {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
    > li > a,
    > li > span {
        &:hover,
        &:focus {
            color: $black-light;
            background-color: $lightgray;
        }
    }
    > .active > a,
    > .active > span {
        &,
        &:hover,
        &:focus {
            z-index: 2;
            color: $white;
            cursor: default;
            background-color: $green-color;
        }
    }
    > .disabled {
        > span,
        > span:hover,
        > span:focus,
        > a,
        > a:hover,
        > a:focus {
            color: $gra-input;
            cursor: not-allowed;
            background-color: $white;
        }
    }
}
.modal {
    &.quickTempModal{
        .modal-body{
            padding: 0 !important;
        }
    }
    &.tooltip-popup {
        .modal-wrapper {
            max-width: 500px;
            width: 90%;
            &.popup_500{
                max-width: 500px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_540 {
                max-width: 540px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_600 {
                max-width: 600px;
            }
            &.popup_310 {
                max-width: 310px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_700 {
                max-width: 700px;
                background: $white;
                border-radius: 10px;
                max-height: 100%;
                overflow: auto;
            }
            &.popup_710 {
                max-width: 700px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_720 {
                max-width: 720px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_750 {
                max-width: 750px;
                background: $white;
                border-radius: 10px;
            }
            &.popup_950 {
                max-width: 950px;
                background: $white;
                border-radius: 10px;
            }
            &.contactus_reportmenu{
                .modal-close {
                    top: 22px;
                    right: 28px;
                    padding: 0;
                    color: #000;
                    font-size: 22px;
                    background: transparent;
                    border: none;
                    position: absolute;
                    cursor: pointer;
                    img{
                        width: unset;
                        height: unset;
                    }
                }
                .title{
                    @include defaultFontStyle(500, 16, 24, $text_color);
                }
                .modal-body{
                    padding: 30px;
                    @include media575{
                        padding: 30px 20px;
                    }
                }
                .contactus_info{
                    margin-top: 30px;
                    padding: 30px;
                    background: #FAF6F7;
                    border-radius: 8px;
                    @include media575{
                        margin-top: 20px;
                        padding: 20px;
                    }
                    .contact_form{
                        .form-group {
                            label{
                                font-weight: 400;
                                margin-bottom: 10px;
                                color: $text_color;
                            }
                            .input-group{
                                border: none;
                                border-radius: 0;
                                .form-control{
                                    border: 1px solid #31313d29 !important;
                                    border-radius: 8px;
                                    background-color: transparent;
                                    @include defaultFontStyle(500, 16, 24, $text_color);
                                    padding: 11px 16px;
                                    &::placeholder{
                                        color: #31313da3;
                                        font-weight: 400;
                                    }
                                }
                                .custom-select{
                                    border: 1px solid #31313d29 !important;
                                    border-radius: 8px;
                                    background-color: transparent;
                                    @include defaultFontStyle(500, 16, 24, $text_color);
                                    padding: 11px 16px;
                                }
                            }
                        }
                    }
                }
                .send_btn{
                    text-align: right;
                    margin-top: 30px;
                    .btn{
                        padding: 13px 20px;
                    }
                }
            }
            &.warning_popoup{
                .title{
                    @include defaultFontStyle(500, 16, 24, $text_color);
                }
                .warning_info{
                    margin-top: 30px;
                    padding: 30px;
                    background: #FAF6F7;
                    border-radius: 8px;
                    h2{
                        @include defaultFontStyle(500, 32, 48, #1B1D26);
                        margin-bottom: 12px;
                    }
                    p{
                        @include defaultFontStyle(400, 16, 24, #1b1d2675);
                    }
                }
                .send_btn{
                    text-align: right;
                    margin-top: 30px;
                    .btn-trans{
                        border: none;
                        &:hover{
                            background-color: transparent;
                            color: #1B1B1B;
                            box-shadow: 0px 0px 17px -5px #353541;
                        }
                    }
                    .btn{
                        padding: 13px 20px;
                    }
                }
            }
            &.add_quick_setting_popoup{
                .modal-close-icon {
                    top: 22px;
                    right: 28px;
                    padding: 0;
                    color: #000;
                    font-size: 22px;
                    background: transparent;
                    border: none;
                    position: absolute;
                    cursor: pointer;
                }
                .title{
                    @include defaultFontStyle(500, 16, 24, $text_color);
                }
                .add_quick_report{
                    margin-top: 30px;
                    padding: 30px;
                    background: #FAF6F7;
                    border-radius: 8px;
                    height: 600px;
                    overflow-y: auto;
                    h2{
                        @include defaultFontStyle(500, 28, 48, #1B1D26);
                        margin-bottom: 20px;
                    }
                    .add_template{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #31313d29;
                        border-radius: 8px;
                        padding: 11px 10px;
                        cursor: pointer;
                        margin-bottom: 30px;
                        @include defaultFontStyle(500, 16, 24, $text_color);
                        img{
                            margin: 0;
                            margin-right: 10px;
                        }
                    }
                    .template_info{
                        h3{
                            @include defaultFontStyle(500, 24, 32, $black);
                            margin-bottom: 30px;
                        }
                        .template_group{
                           
                            .template_item{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                background: $white;
                                border-radius: 8px;    
                                padding: 12px;
                                margin-bottom: 12px;
                                border: 1px solid transparent;
                                cursor: pointer;
                                &:hover{
                                    border: 1px solid $green-btn-light;

                                }
                                &.active{
                                    border: 1px solid $green-btn-light;
                                }
                                .checkbox{
                                    margin-right: 0px;
                                }
                                .dropdown{
                                    position: relative;
                                    img{
                                        margin: 0;
                                        margin-left: 10px;
                                        cursor: pointer;
                                    }
                                    .dropdown-menu{
                                        padding: 0;
                                        background-color: $white;
                                        width: 230px;
                                        white-space: nowrap;
                                        position: absolute;
                                        top: calc(100% + 7px);
                                        left: -170px;
                                        z-index: 1;
                                        visibility: hidden;
                                        opacity: 0;
                                        transform: translateY(30px);
                                        transition: all 0.5s ease 0s;
                                        border-radius: 4px;
                                        -webkit-border-radius: 4px;
                                        -moz-border-radius: 4px;
                                        box-sizing: border-box;
                                        box-shadow: 0px 0px 8px 2px #00000017;
                                        right: 0;
                                        margin: 0 auto;
                                        padding: 0px 16px;
                                        // &:before{
                                        //     position: absolute;
                                        //     top: -17px;
                                        //     left: 0;
                                        //     display: block;
                                        //     content: "";
                                        //     width: 0;
                                        //     border-style: solid;
                                        //     border-width: 0 10px 18px;
                                        //     border-color: transparent transparent #FFFFFF;
                                        //     box-sizing: border-box;
                                        //     right: 0;
                                        //     margin: 0 auto;
                                        //     height: 0;
                                        // }
                                        
                                        .dropdown-item {
                                            position: relative;
                                            padding: 15px 0;
                                            border-bottom: 1px solid #31313d29;
                                            display: block;
                                            width: 100%;
                                            clear: both;
                                            font-weight: 400;
                                            color: #23282c;
                                            text-align: inherit;
                                            white-space: nowrap;
                                            background-color: transparent;
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            @include defaultFontStyle(400, 16, 24, $text_color);
                                            img{
                                                margin-right: 15px;
                                            }
                                            &:last-child{
                                                border-bottom:0;
                                            }
                                        }
                                       
                                    }
                                    &:hover{
                                        .dropdown-menu{
                                            transform: translateY(10px);
                                            visibility: visible;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .report-menu{
                        .report-menu-section{
                            height: 435px;
                        }
                        .row{
                            margin-right: -8px;
                            margin-left: -8px;
                        }
                        
                    }
                    .player_type{
                        .player_group{
                            // padding: 0 30px;
                            .checkbox{
                                border: 1px solid #31313d29;
                                border-radius: 8px;
                                padding: 2px 16px;
                                margin-bottom: 16px;
                                background: transparent;
                                &.check{
                                    background: $white;
                                    border: 1px solid $green-btn-light;
                                }
                                label{
                                    @include defaultFontStyle(4 00, 16, 24, $black);

                                }
                            }
                        }
                    }
                    .report_name_info{
                        .report_name_form{
                            // padding: 0 30px;
                            .form-group {
                                label{
                                    font-weight: 400;
                                    margin-bottom: 10px;
                                    color: $text_color;
                                }
                                .form-control{
                                    background: transparent;
                                }
                            }
                        }
                    }
                   
                }
                .send_btn{
                    text-align: right;
                    margin-top: 30px;
                    .btn{
                        padding: 13px 10px;
                    }
                }
                .time-line-section{
                    margin: 20px 0;
                    @include media767{
                        flex-wrap: wrap;
                    }
                    @include media675{
                        display: block;
                    }
                    .time-line{
                        @include media767{
                            margin: auto;
                        }
                        @include media991{
                            padding: 11px 10px;
                        }
                        .timeline-scoll{
                            .timeline-inner{
                                margin-bottom: 0;
                                li{
                                    width: 95px;
                                    @include media575{
                                        width: 90px;
                                    }
                                
                                    &::before{
                                        width: 58%;
                                        left: -27px;
                                        @include media575{
                                            width: 58%;
                                            left: -26px;
                                        }
                                    }
                                    a{
                                        width: 40px;
                                        height: 40px;
                                        font-size: 17px;
                                    }
                                }
                            }
                        }
                    }
                    .send_btn{
                        margin-top: 0;
                        @include media767{
                            margin: auto;
                            margin-top: 10px;
                            text-align: center;
                            .btn{
                                padding: 13px 15px;
                            }
                        }
                    }
                }
            }
            .modal-close {
                img {
                    width: 35px;
                    height: 35px;
                }
            }
            .modal-body {
                padding: 20px 30px;
                h3 {
                    @include defaultFontStyle(500, 22, 18, $black-light);
                    margin-bottom: 15px;
                }
                p {
                    @include defaultFontStyle(400, 18, 18, $black-dark);
                    margin-bottom: 0;
                }
            }
        }
    }
}
.info_msg{
    display: flex;
    align-items: center;
    background: #24AAF3;
    border-radius: 8px;
    padding: 10px 26px 10px 10px;
    position: relative;
    margin: 10px 0;
    img{
        margin: 0;
        margin-right: 10px;
    }
    p{
        @include defaultFontStyle(500, 12, 16, $white);
        margin: 0;
    }
    span{
        position: absolute;
        top: 11px;
        right: 0;
        img{
            filter: brightness(0) invert(1);
        }
    }
}
.generate-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media991 {
        display: block;
    }
}
.time-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    flex-wrap: wrap;
    // height: 85px;
    overflow: hidden;
    z-index: 0;

    @include media991 {
        height: 100%;
        padding: 20px 10px;
    }
    .timeline-scoll {
        margin-right: 20px;
        @include media480 {
            // overflow: hidden;
            // overflow-x: scroll;
            // padding-bottom: 20px;
            width: 100%;
        }
        .timeline-inner {
            display: flex;
            @include media480 {
                width: 100%;
            }
            li {
                list-style: none;
                position: relative;
                width: 150px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                @include media991 {
                    width: 110px;
                }
                span{
                    margin-bottom: 7px;
                    white-space: nowrap;
                    font-size: 13px;
                    color: $black-dark;
                    @include media480{
                        white-space: pre-line;
                        text-align: center;
                        height: 34px;
                        font-size: 13px;
                    }
                }
                // @include media767 {
                //     width: 50px;
                // }
                // @include media575 {
                //     margin-right: 4px;
                //     width: 40px;
                // }
                @include media480 {
                    // width: 45px;
                    // margin-right: 15px;
                    top: 77%;
                }
                a {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 3;
                    @include defaultFontStyle(600, 20, 1.2, $text_color);
                    border: 2px solid #31313d29;
                    @include media575 {
                        width: 40px;
                        height: 40px;
                        font-size: 16px;
                    }
                }

                &.active {
                    // &:before {
                    //     background: $green-btn-light;
                    // }

                    a {
                        color: $white;
                        background: $green-btn-light;
                        border: 2px solid $green-btn-light;
                    }
                }

                &.select {
                    // &:before {
                    //     background: $green-btn-light;
                    // }

                    a {
                        color: $white;
                        border: 2px solid $green-btn-light;
                        background: $green-btn-light;
                    }
                }

                &.disable {
                    // &:before {
                    //     background: $green-btn-light;
                    // }

                    a {
                        background: #E2E2E2;
                        cursor: not-allowed;
                    }
                }

                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 6px;
                    background: #31313d29;
                    content: "";
                    width: 70%;
                    left: -52px;
                    @include media991 {
                        left: -35px;
                        width: 64%;
                    }
                    // @include media767 {
                    //     left: -30px;
                    // }
                    // @include media575 {
                    //     left: -26px;
                    // }
                    @include media480{
                        left: -7px;
                        // top: 75%;
                        width: 16px;
                    }
                }

                &:first-child {
                    &:before {
                        content: "";
                        display: none;
                    }
                }
            }
        }
    }
    .all_btn_step {
        display: flex;
        align-items: center;
        @include media767 {
            margin-top: 10px;
        }
        a.btn {
            margin-right: 5px;
            padding: 13px 20px;
            &:last-child {
                margin-right: 0;
            }
            @include media1199 {
                padding: 9px 9px;
                font-size: 13px;
            }
            @include media767 {
                padding: 12px 10px;
                font-size: 14px;
            }
            @include media480 {
                padding: 12px 40px;
                font-size: 13px;
            }
        }
    }
}

// generate report bar

.report-content {
    .report-data {
        .data-comp-title{
            margin-bottom: 0;
        }
        // padding: 30px 0;
        .event-type-filter{
            .steptitle{
                h5{
                    font-size: 14px;
                    margin-bottom: 2px;
                    padding-top: 10px;
                }
            }
            .step-dropdown{
                max-width: 500px;
                width: 100%;
            }
        }
    }

    .report-nodata {
        p {
            @include defaultFontStyle(500, 18, 1.4, $black-dark);
            text-align: center;
            padding: 50px;
        }
    }
    .steptitle-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .steptitle{
            h5{
                margin-bottom: 0;
            }
        }
        .step-dropdown {
            display: flex;
            width: 60%;
            @include media991{
                width: 100%;
            }
            @include media767{
                display: block;
            }
            .basic-multi-select {
                width: 50%;
                margin: 10px;
                @include media767{
                    width: auto;
                }
                .select__control {
                    &:hover{
                        border: 1px solid #353541;
                        color: #28263d;
                    }
                }
            }
        }
    }
}

// Error popup

.error-popup {
    .modal-body {
        padding: 30px;
    }
    h1 {
        @include defaultFontStyle(500, 28, 18, $black-light);
        text-align: center;
        margin: 0;
    }
    table {
        td {
            padding: 6px 8px;
            @include defaultFontStyle(400, 18, 20, $black-light);
        }
    }
    .title-btn{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        button{
            white-space: nowrap;
        }
        .header-title{
            width: 50%;
        }
    }
}
.bg-custom {
    .report-menu-info {
        min-height: 124px;
    }
}
.generate_quick_report{
    margin-top: 15px;
    h5{
        margin-bottom: 15px;
    }
    .templates-btn{
        margin-bottom: 15px;
        @include media575 {
            width: 100%;
        }
    }
}

.az_conference_field {
    & > div{
        flex: 0 0 auto;
        width: auto;
        padding: 0 10px; 
        &:last-child{
            width: 260px;
            @include media480{
                width: 100%; 
            }
        }       
        @include media480{
            width: 100%; 
            margin: 0 0 10px;
        }
        &:first-child {
            .css-2b097c-container {
                min-width: 130px;
            }
            .css-26l3qy-menu>div {
                height: 100%;
            }
        }
    }
    .css-2b097c-container {
        min-width: 345px;
        @include media480{
            width: 100%; 
            min-width: unset;
        }
        & > div{
            border: 0 !important;
            box-shadow: none !important;
        }
    }  
    .az_team_field  .css-2b097c-container{
        min-width: 210px;
    }
    div[class*="-control"] > div:first-child,.react-datepicker-wrapper input[type="text"]{
        height: 45px;
        padding: 0;
        box-shadow: none;
        height: 45px;
        width: 100%;
        padding: 10px;
        border: 1px solid #E5E9F2;
        border-radius: 4px;
        appearance: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2px;
        color: #28263D;        
        background: #E2E2E2;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        &::before{
            content: "";
            position: absolute;
            right: 10px;
            top: -3px;
            width: 10px;
            height: 10px;
            border-top: 2px solid;
            border-left: 2px solid;
            transform: rotate(-135deg);
            bottom: 0;
            margin: auto;
            border-radius: 2px;
        }
        div[class*="-placeholder"]{
            color: #28263D;
        }
    }
    .react-datepicker__input-container{
        max-width: 100%;
        position: relative;
        @include media480{
            width: 100%; 
            max-width: 100%;
        }
        &::before{
            content: "";
            position: absolute;
            right: 10px;
            top: -3px;
            width: 10px;
            height: 10px;
            border-top: 2px solid;
            border-left: 2px solid;
            transform: rotate(-135deg);
            bottom: 0;
            margin: auto;
            border-radius: 2px;
            z-index: 9;
            pointer-events: none;
        }
        input[type="text"]::placeholder{
            color: #28263D;
        }
    }
    .react-datepicker-wrapper{
        width: 100%;
        @include media480{
            width: 100%; 
        }
    }
    div[class*="-singleValue"]{
        height: 30px;
        line-height: 30px;
        padding-right: 25px;
    }
    .css-26l3qy-menu > div {
        height: 200px;
    }
}

.az_table_green,.az_table_black_icon {
    margin: 0;
    color: #1B9D06;
    font-weight: 500;    
}
.az_table_black_icon{
    color: #353541;
    position: relative;
    display: flex;
    align-items: center;
    // min-width: 190px;
    @include media991 {
        width: 200px;
    }
    img{
        margin: 0 0 0 15px;
        max-width: 14px;
        height: auto;
        max-height: 100%;
        cursor: pointer;
        @include media991 {
            margin: 0 10px 0 5px;
        }
    }    
}

.az_traditional_table{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label{
        margin-right: 15px;
    }
}

.modal.tooltip-popup .error_popoup {
    .modal-body{
        padding: 50px 30px;
    }
    .details {
        .title{
            text-align: center;            
            @include defaultFontStyle(500, 28, 24, $text_color);
            color: #e10505;
            margin: 0 0 20px;
        }
        .content{
            @include defaultFontStyle(400, 18, 26, $black);
            text-align: center;
        }
    }
}

.react-datepicker__header a.datearrow {
    color: #000;
}

@include media480{
    .from_date .react-datepicker-popper[data-placement^="top"]{
        left: 0 !important;
    }

    .to_date .react-datepicker-popper[data-placement^="bottom"]{
         left: -92px !important;
    } 
    .to_date .react-datepicker-popper[data-placement^="top"]{
        left: -94px !important;
    }
}   

.react-datepicker__header > div{
    margin: 10px !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
}

.react-datepicker__header a.datearrow {
    color: #000;
}


.basic-multi-select .select__control.css-yk16xz-control{
    min-height: 43px;
    border: 1px solid #E5E9F2;
}

.row.report_filter_inner{
    align-items: end;
}

.basic-multi-select .css-1pahdxg-control:hover{
    border: 1px solid #353541;
    color: #28263D;
    outline: none;
    box-shadow: none;
    min-height: 43px;
}

.basic-multi-select .select__control.css-yk16xz-control .select__placeholder{
    box-shadow: none;
    height: 45px;
    width: 100%;
    padding: 10px 25px 10px 0;
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: #353541;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    appearance: none;
}


// custom dropdown
  .custom_dropdown {
    .dropdown-toggle {
      color: #23282c;
      background-color: transparent !important;
      border-color: #e4e7ea;
    }
    .btn {
      background-color: transparent;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:active {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
        box-shadow: none !important;
      }
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active {
      color: #23282c;
      background-color: transparent;
      border-color: #a5aeb7;
    }
    .dropdown-menu {
      width: 100%;
      overflow: auto;
      .checkbox {
        display: grid;
        padding: 6px 6px 6px 30px;
        label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            padding-bottom: 8px;
            font-size: 16px;
            line-height: 20px;
        }
      }
      .select-text{
        span{
          cursor: pointer;
        }
      }
    }
  }
  .custome-height{
    .dropdown-menu {
      width: 100%;
      height: 250px;
    }
  }

  .filter_colmn {
    .custom_dropdown {
        .form-check-label {
            >input[type="checkbox"] {
                position: static;
                opacity: 1;
                height: 12px;
                width: 12px;
            }
        }
    }
}

.custom_dropdown{
    .dropdown{
        position: relative;
    }

   .btn {
        width: 100%;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff !important;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0;
        margin: 0.125rem 0 0;
        font-size: .875rem;
        color: #23282c;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #c8ced3;
        border-radius: 0.25rem;
    }
} 

.custom_dropdown.custome-height.dropdown{
    position: relative;
}

.custom_dropdown.custome-height.dropdown.show .dropdown-menu.show{
    display: block;
}

.report_filter_inner{
    .custom_dropdown{
        .btn{
            position: relative;
            border-radius: 4px;
            &::after{
                content: '';
                position: absolute;
                background: url(../../assets/images/social/down_arrow.svg) center top no-repeat;
                color: #000;
                right: 20px;
                width: 14px;
                height: 8px;
            }
        }
    } 
}
